import { PrimaryWrapper, PrimaryBtn } from "./primary";
import { OutlineBtn, OutlineWrapper } from "./outline";

export const Button = {
  Primary: PrimaryBtn,
  Outline: OutlineBtn,
};

export const ButtonWrapper = {
  Primary: PrimaryWrapper,
  Outline: OutlineWrapper,
};
